<template>
  <section>
    <div class="subscription__sub-header-wrapper">
      <subscription-header
        v-if="audience"
        show-all
        :subscription="subscription"
      />
    </div>
    <user
      v-for="user in users"
      :key="user.id"
      :user="user"
    />
    <list-loader
      v-show="users && users.length"
      :is-loading="isLoading"
      :has-next="hasNext"
      @load="fetchMore"
    />
    <empty-list
      v-if="users && !users.length && !isLoading"
      :gender="gender"
    />
  </section>
</template>

<script lang="ts" src="./Subscription.ts" />
<style lang="scss" src="./subscription.scss" />
