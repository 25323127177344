import { computed, defineComponent } from 'vue';
import { Size } from 'core/styles';
import { TxtType } from 'components/Txt';
import { IconName, IconType } from 'components/Icon';
import { onBeforeRouteUpdate, RouteLocationNormalized, useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { SubscriptionRoutesName } from 'router/names';
import { useStore } from 'vuex';
import { SubscriptionAction, SubscriptionGetter, SUBSCRIPTIONS_STORE_KEY } from 'store/subscriptions';
import TabBar from 'layouts/MainLayout/components/TabBar';
import { USER_STORE_KEY, UsersGetter } from 'store/users';
import EmptySubscriptions from './components/EmptySubscriptions';

const SubscriptionsLayout = defineComponent({
  components: {
    TabBar,
    EmptySubscriptions,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const { t } = useI18n();
    const title = computed(() => t(`layouts.subscriptionsLayout.titles.${route.name as SubscriptionRoutesName}`));

    onBeforeRouteUpdate(fetchSubscriptionUsers);
    fetchSubscriptionUsers(route);
    function toMainSubscriptionsPage() {
      router.push({ name: SubscriptionRoutesName.Subscriptions });
    }
    async function fetchSubscriptionUsers(to: RouteLocationNormalized) {
      if (to.name === SubscriptionRoutesName.Subscription) {
        const subscriptionId = parseInt(to.params.id as string, 10);
        if (subscriptionId) {
          try {
            await store.dispatch(
              `${SUBSCRIPTIONS_STORE_KEY}/${SubscriptionAction.FetchSubscriptionUsers}`, { subscriptionId },
            );
          } catch (e) {
            toMainSubscriptionsPage();
          }
        }
      }
    }

    const iconDir = computed(() => {
      switch (route.name as SubscriptionRoutesName) {
        case SubscriptionRoutesName.Subscriptions: return 'right';
        case SubscriptionRoutesName.Subscription: return 'left';
        default: return '';
      }
    });

    const profile = computed(() => store.getters[`${USER_STORE_KEY}/${UsersGetter.Profile}`]);
    const subscriptions = computed(() => store.getters[`${SUBSCRIPTIONS_STORE_KEY}/${SubscriptionGetter.Subscriptions}`]);

    function onCrossesClick() {
      if (route.name === SubscriptionRoutesName.Subscriptions) router.push('/');
      else router.push({ name: SubscriptionRoutesName.Subscriptions });
    }

    return {
      title,
      profile,
      subscriptions,
      iconDir,
      onCrossesClick,

      IconName,
      IconType,
      Size,
      TxtType,
      SubscriptionRoutesName,
    };
  },
});

export default SubscriptionsLayout;
