<template>
  <about-subscription @submit="toCreatePage" />
  <section class="subscriptions">
    <div class="subscriptions__create">
      <btn
        center
        w="100%"
        @click="$router.push({ name: SubscriptionRoutesName.SubscriptionCreate })"
      >
        {{ $t('pages.subscriptions.create') }}
      </btn>
    </div>
    <subscription
      v-for="subscription in subscriptions"
      :key="subscription.id"
      :subscription="subscription"
      :total="subscription.latest_users.length"
      class="subscriptions__item"
    />
  </section>
</template>

<script lang="ts" src="./Subscriptions.ts"></script>
<style lang="scss" src="./subscriptions.scss"></style>
