import { computed, defineComponent } from 'vue';
import { Sex } from 'api/users/models/user';
import { IconType, IconName } from 'components/Icon';
import { TxtType, TxtWeight } from 'components/Txt';
import { Size, MainColor } from 'core/styles';
import { BtnType } from 'components/Btn';
import { ModalHeader, ModalHeaderDirection } from 'components/Modal';
import Prompt from 'components/Prompt';
import MainLayout from 'layouts/MainLayout';
import { Nullable } from 'core/types';
import { useStore } from 'vuex';
import { USER_STORE_KEY, UsersGetter } from 'store/users';
import { SubscriptionGetter, SUBSCRIPTIONS_STORE_KEY } from 'store/subscriptions';
import { RouteNames, SubscriptionRoutesName } from 'router/names';
import { ISubscription } from 'api/users/models/user/interfaces/ISubscription';
import { useRouter } from 'vue-router';
import Subscription from './components/Subscription';
import AboutSubscription from '../components/About';

// import List from '../Catalog/components/List';

const Subscriptions = defineComponent({
  name: RouteNames.Subscriptions,
  components: {
    // List,
    ModalHeader,
    MainLayout,
    Prompt,
    Subscription,
    AboutSubscription,
  },

  setup() {
    const store = useStore();
    const router = useRouter();
    const profile = computed(() => store.getters[`${USER_STORE_KEY}/${UsersGetter.Profile}`]);

    const subscriptions = computed<Nullable<ISubscription[]>>(
      () => store.getters[`${SUBSCRIPTIONS_STORE_KEY}/${SubscriptionGetter.Subscriptions}`],
    );

    function toCreatePage() {
      router.push({ name: RouteNames.SubscriptionCreate });
    }

    return {
      profile,
      subscriptions,
      toCreatePage,

      ModalHeaderDirection,
      TxtType,
      TxtWeight,
      BtnType,
      IconName,
      IconType,
      Size,
      MainColor,
      Sex,
      SubscriptionRoutesName,
    };
  },
});

export default Subscriptions;
