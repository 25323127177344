import { render } from "./EmptySubscriptions.vue?vue&type=template&id=17d59278"
import script from "./EmptySubscriptions.ts?vue&type=script&lang=ts"
export * from "./EmptySubscriptions.ts?vue&type=script&lang=ts"

import "./emptySubscriptions.scss?vue&type=style&index=0&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "17d59278"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('17d59278', script)) {
    api.reload('17d59278', script)
  }
  
  module.hot.accept("./EmptySubscriptions.vue?vue&type=template&id=17d59278", () => {
    api.rerender('17d59278', render)
  })

}

script.__file = "src/layouts/SubscriptionsLayout/components/EmptySubscriptions/EmptySubscriptions.vue"

export default script