import { computed, defineComponent } from 'vue';
import { PropType } from '@vue/runtime-core';
import TilePhotos from 'components/TilePhotos';
import { BtnType } from 'components/Btn';
import { TxtWeight } from 'components/Txt';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { USER_STORE_KEY, UsersGetter } from 'store/users';
import { IProfile } from 'api/users/models/user';
import { SubscriptionRoutesName } from 'router/names';
import SubscriptionHeader from 'views/Subscriptions/components/SubscriptionHeader';
import EmptyList from 'views/Subscriptions/components/EmptyList';
import { ISubscription } from 'api/users/models/user/interfaces/ISubscription';
import { useFetching } from 'composables/fetching';

const Subscription = defineComponent({
  components: {
    TilePhotos,
    SubscriptionHeader,
    EmptyList,
  },
  props: {
    subscription: {
      type: Object as PropType<ISubscription>,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
  },
  emits: ['showMore'],
  setup(props) {
    const store = useStore();
    const router = useRouter();

    const { isFetching, fetch } = useFetching();
    function toSubscriptionPage() {
      if (isFetching.value) return;
      fetch(router.push({ name: SubscriptionRoutesName.Subscription, params: { id: props.subscription.id } }), false);
    }

    const gender = computed(() => {
      if (props.subscription.audience?.gender) return props.subscription.audience.gender;
      const profile = store.getters[`${USER_STORE_KEY}/${UsersGetter.Profile}`] as IProfile;
      return profile.oppositeGender;
    });

    return {
      toSubscriptionPage,
      gender,

      BtnType,
      TxtWeight,
    };
  },
});

export default Subscription;
