import { computed, defineComponent, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { SubscriptionAction, SubscriptionGetter, SUBSCRIPTIONS_STORE_KEY } from 'store/subscriptions';
import User from 'views/Catalog/components/User';
import { TxtType } from 'components/Txt';
import { MainColor, Size } from 'core/styles';
import { IconName, IconType } from 'components/Icon';
import { IProfile, IUser } from 'api/users/models/user';
import { Nullable } from 'core/types';
import ListLoader from 'components/ListLoader';
import { USER_STORE_KEY, UsersGetter } from 'store/users';
import { ISubscription } from 'api/users/models/user/interfaces/ISubscription';
import { IApiPaginateResponse } from 'api/api-request';
import { useApiErrors } from 'composables/apiErrors';
import { RouteNames } from 'router/names';
import SubscriptionHeader from '../components/SubscriptionHeader';
import EmptyList from '../components/EmptyList';

const Subscription = defineComponent({
  name: RouteNames.Subscription,
  components: {
    User,
    SubscriptionHeader,
    ListLoader,
    EmptyList,
  },
  setup() {
    const route = useRoute();
    const store = useStore();
    const { showError } = useApiErrors();
    const subscriptionId = parseInt(route.params.id as string, 10);

    const subscription = computed<Nullable<ISubscription>>(
      () => store.getters[`${SUBSCRIPTIONS_STORE_KEY}/${SubscriptionGetter.Subscription}`](subscriptionId),
    );

    const subscriptionUsers = computed<IApiPaginateResponse<IUser[]>>(
      () => store.getters[`${SUBSCRIPTIONS_STORE_KEY}/${SubscriptionGetter.SubscriptionUsers}`](subscriptionId),
    );

    const hasNext = computed(() => subscriptionUsers.value?.meta.current_page < subscriptionUsers.value?.meta.last_page);
    const users = computed<Nullable<IUser[]>>(() => subscriptionUsers.value?.data || null);
    const audience = computed(() => subscription.value?.audience || null);

    const isLoading = ref(false);
    async function fetchMore() {
      isLoading.value = true;
      try {
        await store.dispatch(`${SUBSCRIPTIONS_STORE_KEY}/${SubscriptionAction.FetchSubscriptionUsers}`, {
          subscriptionId,
          page: subscriptionUsers.value.meta.current_page + 1,
        });
      } catch (e) {
        showError(e);
      } finally {
        isLoading.value = false;
      }
    }

    const gender = computed(() => {
      if (subscription.value?.audience?.gender) return subscription.value.audience.gender;
      const profile = store.getters[`${USER_STORE_KEY}/${UsersGetter.Profile}`] as IProfile;
      return profile.oppositeGender;
    });

    return {
      isLoading,
      subscription,
      users,
      audience,
      gender,
      hasNext,
      fetchMore,

      TxtType,
      Size,
      IconName,
      IconType,
      MainColor,
    };
  },
});

export default Subscription;
