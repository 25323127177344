import { computed, defineComponent } from 'vue';
import { Sex } from 'api/users/models/user';
import { PropType } from '@vue/runtime-core';
import { TxtType, TxtWeight } from 'components/Txt';
import { IconName, IconType } from 'components/Icon';
import { MainColor } from 'core/styles';
import { SubscriptionRoutesName } from 'router/names';

const EmptySubscriptions = defineComponent({
  props: {
    sex: {
      type: String as PropType<Sex>,
      required: true,
    },
  },
  setup(props) {
    const iconName = computed(() => (props.sex === Sex.Male ? IconName.Female : IconName.Male));

    return {
      iconName,

      TxtWeight,
      TxtType,
      IconType,
      MainColor,
      SubscriptionRoutesName,
    };
  },
});

export default EmptySubscriptions;
