<template>
  <article class="subscriptions-empty-list">
    <img
      v-if="gender === Sex.Female"
      src="./assets/empty-female.svg"
      alt="empty-list"
    >
    <img
      v-else
      src="./assets/empty-male.svg"
      alt="empty-list"
    >
    <txt
      class="subscriptions-empty-list__txt"
      :weight="TxtWeight.Bold"
    >
      {{ $t('pages.subscriptions.emptyUsers') }}
    </txt>
  </article>
</template>

<script lang="ts" src="./EmptyList.ts" />
<style lang="scss" src="./emptyList.scss" />
