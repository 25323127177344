import { computed, defineComponent, ref, watch, watchEffect } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { UsersGetter, useUsersGetter } from 'store/users';
import { Size } from 'core/styles';
import { TxtType, TxtWeight } from 'components/Txt';
import { ModalHeaderDirection } from 'components/Modal';
import { BtnType } from 'components/Btn';
import Prompt from 'components/Prompt';
import { ServiceNames, useServiceSettings } from 'composables/serviceSettings';
import CreateSubscription from 'components/CreateSubscription';
import { RouteNames, SubscriptionRoutesName } from 'router/names';
import { IUserFilter } from 'api/users/models/user/interfaces/IUserFilter';
import FilterLayout from 'layouts/FilterLayout';
import { ICreateSubscription } from 'api/subscriptionsService';
import { useStore } from 'vuex';
import { SubscriptionAction, SubscriptionGetter, SUBSCRIPTIONS_STORE_KEY } from 'store/subscriptions';
import { Nullable } from 'core/types';
import { ISubscription } from 'api/users/models/user';
import { useI18n } from 'vue-i18n';
import { useApiErrors } from 'composables/apiErrors';
import { storageService, StorageKeys } from 'storage';
import { useRules } from 'composables/rules';
import FilterForm from '../../Catalog/components/FilterForm';

const Filter = defineComponent({
  name: RouteNames.SubscriptionCreate,
  components: {
    Prompt,
    FilterForm,
    CreateSubscription,
    FilterLayout,
  },

  setup() {
    const { t } = useI18n();
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const { showError } = useApiErrors();

    const subscriptionEditId = computed(() => parseInt(route.params.id as string, 10));

    const editedSubscription = computed<Nullable<ISubscription>>(() => {
      if (Number.isNaN(subscriptionEditId.value)) return null;
      return store.getters[`${SUBSCRIPTIONS_STORE_KEY}/${SubscriptionGetter.Subscription}`](subscriptionEditId.value);
    });
    const isEdit = computed(() => !!editedSubscription.value);

    const submitTitle = computed(() => {
      if (isEdit.value) return t('pages.createSubscription.edit');
      return t('pages.createSubscription.subscribe');
    });

    const defaultFilter = useUsersGetter<IUserFilter>(UsersGetter.FilterDefaults);

    const filter = ref<IUserFilter>({ ...defaultFilter });
    const name = ref('');
    const nameRef = ref();

    watchEffect(() => {
      if (editedSubscription.value) {
        filter.value = {
          ...defaultFilter,
          ...editedSubscription.value.audience,
        };
        name.value = editedSubscription.value.name;
      }
    });

    const model = computed<ICreateSubscription>(() => ({
      name: name.value,
      ...filter.value,
    }));

    const localStorageFilters = !isEdit.value && storageService.getItem(StorageKeys.SubscriptionFilter);
    if (localStorageFilters) {
      const parsedLocalStorageFilters = JSON.parse(localStorageFilters);
      if (typeof parsedLocalStorageFilters !== 'object' || parsedLocalStorageFilters === null) {
        storageService.removeItem(StorageKeys.SubscriptionFilter);
        return;
      }
      filter.value = {
        ...filter.value,
        age: parsedLocalStorageFilters.age,
        gender: parsedLocalStorageFilters.gender,
        height: parsedLocalStorageFilters.height,
        hair: parsedLocalStorageFilters.hair,
        breast: parsedLocalStorageFilters.breast,
        hips: parsedLocalStorageFilters.hips,
        activity: parsedLocalStorageFilters.activity,
        body: parsedLocalStorageFilters.body,
        interests: parsedLocalStorageFilters.interests,
        photos: parsedLocalStorageFilters.photos,
        city_id: parsedLocalStorageFilters.city_id,
      };

      name.value = parsedLocalStorageFilters.name || '';
    }

    watch(model, (value) => {
      if (!isEdit.value) storageService.setItem(StorageKeys.SubscriptionFilter, JSON.stringify({ ...value }));
    }, { deep: true });

    const serviceInfo = useServiceSettings(ServiceNames.Subscription);
    const submitPrompt = ref(false);
    const confirmPrompt = ref(false);
    const insufficientPrompt = ref(false);

    function validate() {
      const nameValid = nameRef.value?.validate();
      if (nameValid) return true;

      nameRef.value.focus();
      return false;
    }

    async function onSubmit() {
      if (!validate()) return;
      if (isEdit.value) {
        try {
          await store.dispatch(`${SUBSCRIPTIONS_STORE_KEY}/${SubscriptionAction.ChangeSubscription}`, {
            id: editedSubscription.value?.id,
            payload: model.value,
          });
          onSuccess();
        } catch (e) {
          showError(e);
        }
      } else submitPrompt.value = true;
    }

    function reduceHairs(hairs: string[]) {
      return hairs.reduce((acc, value) => `${acc}${acc ? ', ' : ''} ${value.toLowerCase()}`, '');
    }

    function onSuccess() {
      filter.value = { ...defaultFilter };
      name.value = '';
      router.push({ name: SubscriptionRoutesName.Subscriptions });
      if (!isEdit.value) storageService.removeItem(StorageKeys.SubscriptionFilter);
    }

    function onModalClose() {
      router.back();
    }

    const { maxLength, minLength } = useRules();

    // eslint-disable-next-line consistent-return
    return {
      minLength: minLength(2),
      maxLength: maxLength(255),
      serviceInfo,
      filter,
      model,
      name,
      nameRef,
      submitPrompt,
      insufficientPrompt,
      confirmPrompt,
      submitTitle,

      onModalClose,
      onSuccess,
      reduceHairs,
      onSubmit,

      Size,
      TxtType,
      TxtWeight,
      BtnType,
      ModalHeaderDirection,
    };
  },
});

export default Filter;
