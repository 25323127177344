import { render } from "./SubscriptionsLayout.vue?vue&type=template&id=f7f7663c"
import script from "./SubscriptionsLayout.ts?vue&type=script&lang=ts"
export * from "./SubscriptionsLayout.ts?vue&type=script&lang=ts"

import "./subscriptionsLayout.scss?vue&type=style&index=0&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "f7f7663c"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('f7f7663c', script)) {
    api.reload('f7f7663c', script)
  }
  
  module.hot.accept("./SubscriptionsLayout.vue?vue&type=template&id=f7f7663c", () => {
    api.rerender('f7f7663c', render)
  })

}

script.__file = "src/layouts/SubscriptionsLayout/SubscriptionsLayout.vue"

export default script