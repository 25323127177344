<template>
  <aside class="empty-subscriptions">
    <div class="empty-subscriptions__avatar-wrapper">
      <icon
        class="empty-subscriptions__avatar"
        :name="iconName"
        :type="IconType.Big"
        :color="MainColor.Grey4"
      />
    </div>

    <txt
      :type="TxtType.Heading2"
      class="empty-subscriptions__title"
    >
      {{ $t('pages.subscriptions.empty.title') }}
    </txt>

    <txt
      :weight="TxtWeight.Medium"
      class="empty-subscriptions__content"
    >
      {{ $t(`pages.subscriptions.empty.${sex}Description`) }}
    </txt>

    <btn
      w="75%"
      @click="$router.push({ name: SubscriptionRoutesName.SubscriptionCreate })"
    >
      {{ $t('pages.subscriptions.empty.selectType') }}
    </btn>
  </aside>
</template>

<script lang="ts" src="./EmptySubscriptions.ts" />
<style lang="scss" src="./emptySubscriptions.scss" />
