<template>
  <header
    class="subscriptions-layout__header"
  >
    <icon-btn
      v-if="iconDir === 'left'"
      class="subscriptions-layout__back"
      @click="$router.back()"
    >
      <icon
        :name="IconName.ArrowBack"
        :type="IconType.Contour"
      />
    </icon-btn>

    <txt :type="TxtType.Heading4">
      {{ title }}
    </txt>

    <icon-btn
      v-if="iconDir === 'right'"
      class="subscriptions-layout__close"
      @click="onCrossesClick"
    >
      <icon
        :name="IconName.Crosses"
        :type="IconType.Contour"
        :size="Size.Xs"
      />
    </icon-btn>
  </header>
  <main
    class="subscriptions-layout__main"
  >
    <empty-subscriptions
      v-if="!subscriptions || !subscriptions.length"
      :sex="profile.gender"
    />
    <router-view />
    <tab-bar />
  </main>
</template>

<script lang="ts" src="./SubscriptionsLayout.ts" />
<style lang="scss" src="./subscriptionsLayout.scss" />
