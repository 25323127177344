<template>
  <div
    class="subscription-item-header"
    @click="handleWrapperClick"
  >
    <prompt
      v-model="deactivatePrompt"
      :width="293"
      :title="$t('pages.subscriptions.subscriptionHeader.deactivatePrompt.title')"
      ok-text-path="pages.subscriptions.subscriptionHeader.deactivatePrompt.ok"
      @ok="deactivateSubscription"
    />
    <prompt
      v-model="activatePrompt"
      :width="293"
      :title="serviceInfo.boughtText || $t('pages.subscriptions.subscriptionHeader.activatePrompt.title', { price: formattedPrice })"
      ok-text-path="pages.subscriptions.subscriptionHeader.activatePrompt.ok"
      @ok="activateSubscription"
    />
    <prompt
      v-model="removePrompt"
      :width="293"
      :title="$t('pages.subscriptions.subscriptionHeader.removePrompt.title')"
      ok-text-path="pages.subscriptions.subscriptionHeader.removePrompt.ok"
      @ok="removeSubscription"
    />
    <insufficient-prompt v-model="insufficientPrompt" />
    <icon-btn
      class="subscription-item-header__close"
      :disabled="isFetching"
      @click.stop="openRemovePrompt"
    >
      <icon
        :name="IconName.Crosses"
        :type="IconType.Contour"
        :size="Size.Xxs"
      />
    </icon-btn>

    <txt
      :weight="TxtWeight.SemiBold"
      :type="TxtType.Heading4"
      class="subscription-item-header__title-wrapper"
    >
      {{ subscription.name }}
    </txt>

    <txt
      :type="TxtType.SmallText"
    >
      <span
        v-for="filter in subtitles"
        :key="filter"
        class="subscription-item-header__subtitle"
      >{{ filter }}</span>
    </txt>
    <label
      class="subscription-item-header__toggler"
      @click.stop="openDeactivatePrompt"
    >
      {{ $t(`pages.subscriptions.subscriptionHeader.${toggler ? '' : 'in'}active`) }}
      <toggler
        :model-value="toggler"
        disabled
      />
    </label>

    <router-link
      :to="{ name: SubscriptionRoutesName.SubscriptionEdit, params: { id: subscription.id } }"
      class="subscription-item-header__edit"
      @click.stop
    >
      <span>{{ $t('pages.subscriptions.subscriptionHeader.edit') }}</span>
      <icon
        :name="IconName.Pencil"
        :type="IconType.Contour"
        class="ml-1"
      />
    </router-link>
  </div>
</template>

<script lang="ts" src="./SubscriptionHeader.ts" />
<style lang="scss" src="./subscriptionHeader.scss" />
