import { defineComponent } from 'vue';
import { TxtWeight } from 'components/Txt';
import { Sex } from 'api/users/models/user';
import { PropType } from '@vue/runtime-core';

const EmptyList = defineComponent({
  props: {
    gender: {
      type: String as PropType<Sex>,
      default: Sex.Female,
    },
  },
  setup() {
    return {
      TxtWeight,
      Sex,
    };
  },
});

export default EmptyList;
