<template>
  <article class="subscription-item">
    <tile-photos
      :users="subscription.latest_users"
      :size="9"
      :total="total"
    >
      <template
        #header
        @to-subscription-page="toSubscriptionPage"
      >
        <subscription-header
          :subscription="subscription"
          @click="toSubscriptionPage"
        />
      </template>
      <template
        #footer
      >
        <!-- todo: change to '<' -->
        <btn
          v-if="total >= 9"
          w="100%"
          center
          :type="BtnType.Text"
          class="subscription-item__show-more"
          @click="toSubscriptionPage"
        >
          {{ $t('pages.subscriptions.showMore') }}
        </btn>
      </template>
      <template #empty>
        <empty-list :gender="gender" />
      </template>
    </tile-photos>
  </article>
</template>

<script lang="ts" src="./Subscription.ts" />
<style lang="scss" src="./subscription.scss" />
