import { computed, defineComponent, ref, watchEffect } from 'vue';
import { PropType } from '@vue/runtime-core';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { TxtType, TxtWeight } from 'components/Txt';
import { IconName, IconType } from 'components/Icon';
import { MainColor, Size } from 'core/styles';
import Prompt from 'components/Prompt';
import { SubscriptionAction, SUBSCRIPTIONS_STORE_KEY } from 'store/subscriptions';
import { useRouter } from 'vue-router';
import { SubscriptionRoutesName } from 'router/names';
import { useApiErrors } from 'composables/apiErrors';
import InsufficientPrompt from 'components/InsufficientPrompt';
import { ServiceNames, useServiceSettings } from 'composables/serviceSettings';
import { useCurrency } from 'composables/currency';
import { USER_STORE_KEY, UsersGetter } from 'store/users';
import { IUserFilter } from 'api/users/models/user/interfaces/IUserFilter';
import { ISubscription } from 'api/users/models/user/interfaces/ISubscription';
import { CITIES_STORE_KEY, CitiesGetter } from 'store/cities';
import { useFetching } from 'composables/fetching';

type LocalizedFilters = Partial<Record<keyof IUserFilter, string[]>>;

const SubscriptionHeader = defineComponent({
  components: {
    Prompt,
    InsufficientPrompt,
  },
  props: {
    showAll: {
      type: Boolean,
      default: false,
    },
    subscription: {
      type: Object as PropType<ISubscription>,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const store = useStore();
    const router = useRouter();

    const audience = computed(() => props.subscription.audience);
    const isActive = computed(() => props.subscription.active);
    const { showError } = useApiErrors();
    const serviceInfo = useServiceSettings(ServiceNames.Subscription);
    const { formatCurrency } = useCurrency();

    const getCityById = (id: number) => store.getters[`${CITIES_STORE_KEY}/${CitiesGetter.CityById}`](id);

    const formattedPrice = computed(() => formatCurrency(serviceInfo.value.price));

    const profile = computed(() => store.getters[`${USER_STORE_KEY}/${UsersGetter.Profile}`]);

    const toggler = ref();
    watchEffect(() => {
      toggler.value = isActive.value;
    });

    function getLocalizeFilter(filter: string, key: string, params?: string | Record<string, string>) {
      const str = `pages.subscription.filters.${filter}.${key}${typeof params === 'string' ? `.${params}` : ''}`;
      const result = typeof params === 'object' ? t(str, params) : t(str);
      if (result === str) {
        return '';
      }
      return result;
    }

    const localizedFilters = computed<LocalizedFilters>(() => {
      const localizedFilters: LocalizedFilters = {};

      if (audience.value.hair) {
        localizedFilters.hair = [];
        audience.value.hair.forEach(
          // @ts-ignore
          (v: string) => localizedFilters.hair.push(getLocalizeFilter('hair', v, audience.value.gender)),
        );
      }

      Object.entries(audience.value).forEach(([key, value]) => {
        if (!value) return;
        if (key === 'city_id') {
          const cityName = getCityById(value)?.name;
          if (!cityName) return;
          localizedFilters[key] = [cityName];
          return;
        }
        if (['height', 'age'].includes(key)) {
          let str = '';
          str += getLocalizeFilter(key, 'from', { n: value.from });
          str += ' ';
          str += getLocalizeFilter(key, 'to', { n: value.to });
          localizedFilters[key] = [str];
          return;
        }

        if (['gender', 'body', 'hips', 'breast', 'activity', 'interests', 'photos'].includes(key)) {
          const title = getLocalizeFilter(key, 'title');
          localizedFilters[key] = [];
          if (Array.isArray(value)) {
            if (!value.length) {
              delete localizedFilters[key];
              return;
            }
            value.forEach(
              (v: string) => localizedFilters[key].push(getLocalizeFilter(key, v)),
            );
            localizedFilters[key].sort((a: string, b: string) => a.localeCompare(b));

            if (['hips', 'breast'].includes(key)) {
              let str = '';
              localizedFilters[key].forEach(
                (v: string) => {
                  str += `${str ? ', ' : ''}${v}`;
                },
              );
              localizedFilters[key] = [str];
            }
          } else {
            localizedFilters[key].push(getLocalizeFilter(key, value));
          }
          if (title && localizedFilters[key][0]) {
            localizedFilters[key][0] = `${title} ${localizedFilters[key][0]}`;
          }
        }
      });
      return localizedFilters;
    });

    const subtitles = computed(() => {
      let subtitles: string[] = localizedFilters.value.city_id || [];
      Object.entries(localizedFilters.value).forEach(([key, value]) => {
        if (key === 'city_id') return;
        subtitles = subtitles.concat(value);
      });
      return subtitles;
    });

    const deactivatePrompt = ref(false);
    const activatePrompt = ref(false);
    const removePrompt = ref(false);
    const insufficientPrompt = ref(false);

    const { isFetching, fetch } = useFetching();
    async function removeSubscription() {
      try {
        await fetch(store.dispatch(
          `${SUBSCRIPTIONS_STORE_KEY}/${SubscriptionAction.RemoveSubscription}`, props.subscription.id,
        ).then(() => store.dispatch(`${SUBSCRIPTIONS_STORE_KEY}/${SubscriptionAction.FetchSubscriptions}`)));
        router.push({ name: SubscriptionRoutesName.Subscriptions });
      } catch (e) {
        showError(e);
      }
    }

    function handleWrapperClick(e: Event) {
      if (isFetching.value) e.stopImmediatePropagation();
    }

    async function deactivateSubscription() {
      try {
        await store.dispatch(
          `${SUBSCRIPTIONS_STORE_KEY}/${SubscriptionAction.DeactivateSubscription}`, props.subscription.id,
          );
      } catch (e) {
        showError(e);
      }
    }
    async function activateSubscription() {
      if (!serviceInfo.value.isBought && profile.value.balance < serviceInfo.value.price) {
        insufficientPrompt.value = true;
        activatePrompt.value = false;
        return;
      }
      try {
        await store.dispatch(
          `${SUBSCRIPTIONS_STORE_KEY}/${SubscriptionAction.ActivateSubscription}`, props.subscription.id,
        );
      } catch (e) {
        showError(e);
      }
    }

    function openDeactivatePrompt() {
      if (toggler.value) deactivatePrompt.value = true;
      else activatePrompt.value = true;
    }
    function openRemovePrompt() {
      removePrompt.value = true;
    }

    return {
      formattedPrice,
      audience,
      isActive,
      toggler,
      localizedFilters,
      openDeactivatePrompt,
      openRemovePrompt,
      isFetching,
      serviceInfo,

      deactivatePrompt,
      activatePrompt,
      removePrompt,
      insufficientPrompt,
      activateSubscription,
      deactivateSubscription,
      removeSubscription,

      subtitles,
      handleWrapperClick,

      SubscriptionRoutesName,
      TxtType,
      TxtWeight,
      IconName,
      IconType,
      Size,
      MainColor,
    };
  },
});

export default SubscriptionHeader;
