<template>
  <filter-layout
    :title="$t('pages.createSubscription.title')"
    :dir="ModalHeaderDirection.Rtl"
    @close="onModalClose"
  >
    <filter-form
      v-model="filter"
      :submit-text="submitTitle"
      hide-online-filter
      @submit="onSubmit"
    >
      <template #header>
        <txt
          class="create-subscription-name__title"
          :type="TxtType.Heading4"
        >
          {{ $t('pages.createSubscription.subscriptionName') }}
        </txt>
        <textfield
          ref="nameRef"
          v-model="name"
          :rules="[minLength, maxLength]"
          class="create-subscription-name__input"
          :placeholder="$t('pages.createSubscription.subscriptionNamePlaceholder')"
        />
      </template>
      <template #footer="{ filters, hairs }">
        <txt
          :weight="TxtWeight.Medium"
          :leading="Size.L"
          class="block pb-8"
        >
          {{ $t(`pages.form.typeSubsDesc.${filters.gender}`, { txt: reduceHairs(hairs) || $t(`pages.form.gender.${filters.gender}`) }) }}
        </txt>
      </template>
    </filter-form>
  </filter-layout>

  <create-subscription
    v-model="submitPrompt"
    :filter="model"
    @success="onSuccess"
  />
</template>

<script lang="ts" src="./Create.ts"></script>
<style lang="scss" src="./create.scss" />
